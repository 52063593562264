var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"width":"100%"},attrs:{"color":"primary","dark":"","tile":""},on:{"click":_vm.getOrderLines}},'v-btn',attrs,false),on),[_vm._v(" View Orders "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-clipboard-text ")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"max-height":"100","flat":"","dark":"","color":"secondary-4"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('span',{staticClass:"headline",staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s(_vm.$store.getters.title)+" | Order Lines - "+_vm._s(_vm.projectCode))])],1),_c('v-card-text',[_c('br'),_c('v-layout',{attrs:{"align-center":"","justify-center":"","column":""}},[_c('v-flex',{attrs:{"row":"","align-center":""}},[(_vm.loading)?_c('v-progress-circular',{staticStyle:{"text-align":"center"},attrs:{"indeterminate":"","color":"grey darken-2"}}):_vm._e()],1)],1),(_vm.orderLines.length === 0 && _vm.loading === false)?_c('p',[_vm._v(" No order lines to display! ")]):_vm._e(),(_vm.orderLines.length > 0 && _vm.loading === false)?_c('p',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.orderLines,"search":_vm.search,"custom-sort":_vm.orderLineSort},scopedSlots:_vm._u([{key:"item.delivery_date",fn:function(ref){
var item = ref.item;
return [_c('date-picker',{attrs:{"completed-date":item.date_received,"due-date":item.delivery_date,"disabled":true}})]}},{key:"item.order_date",fn:function(ref){
var item = ref.item;
return [_c('date-picker',{attrs:{"due-date":item.order_date,"disabled":true,"greyed-out":true}})]}}],null,false,4213432895)})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }