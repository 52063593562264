<template>
  <v-app>
    <planning-tool-header></planning-tool-header>

    <!-- Sizes your content based upon application components -->
    <v-main>

      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>

    <planning-tool-footer></planning-tool-footer>

    <v-dialog
      v-model="dialog"
      persistent
      max-width="500"
    >
      <v-card>
        <v-form>
          <v-card-title class="headline">
            LOGIN
          </v-card-title>
          <v-card-text>
            <p class="body-2" :hidden="!loginError" style="color: red;">Invalid login attempt!</p>
            <v-text-field
              v-model="username"
              prepend-inner-icon="mdi-account"
              label="Username"
              @keyup.enter="login"
            >
            </v-text-field>

            <v-text-field
              v-model="password"
              label="Password"
              prepend-inner-icon="mdi-lock"
              type="password"
              @keyup.enter="login"
            >
            </v-text-field>

            <v-checkbox label="Stay logged in" v-model="rememberMeLogin"></v-checkbox>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :loading="loginLoading"
              text
              tile
              @click="login"
            >
              Login
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'PlanningToolUI',
  data() {
    return {
      dialog: this.loginRequest,
      username: '',
      password: '',
      loginError: false,
      loginLoading: false,
      rememberMeLogin: true,
      loginResponse: null
    }
  },
  computed: {
    ...mapGetters([
      'title',
      'loginRequest',
    ])
  },
  methods: {
    login() {
      this.loginResponse = null;

      let this_ = this;
      this.loginLoading = true;

      this.$http.post(
        this.$store.state.loginApiUrl +
          "/" +
          "api-token-auth",
        {
          username: this_.username,
          password: this_.password
        },
      ).then((response) => {
        this.loginResponse = response;

        this.loginSuccess()
      }).catch(() => {
        this.loginFailed()
      })
    },
    updateTitle() {
      document.title = this.title
    },
    loginSuccess() {
      if (this.loginResponse !== null) {
        let response = this.loginResponse;

        this.$store.state.apiToken = 'Token ' + response.data.token;
  
        if (this.rememberMeLogin) {
          // TODO: Improve in the future
          // Set local storage variable
          localStorage.setItem('token', 'Token ' + response.data.token)
        }
        
        this.$store.state.loginRequest = false;
        this.loginLoading = false;
      }

      this.$store.state.refreshData = true;
    },
    loginFailed() {
        this.loginError = true;
        this.loginLoading = false;

        this.$store.state.apiToken = null

        // Set local storage variable
        localStorage.setItem('token', null)
    }
  },
  watch: {
    title() {
      this.updateTitle();
    },
    loginRequest() {
      this.dialog = this.loginRequest;
    }
  },
  created() {
    this.updateTitle();

    this.dialog = this.loginRequest;

    this.$store.state.trelloInstance.authorize({
      type: 'popup',
      name: 'Barrows Planning Tool',
      scope: {
        read: 'true',
        write: 'true' },
      expiration: 'never',
      success: this.loginSuccess,
      error: this.loginFailed
    });
  }
}
</script>