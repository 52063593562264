<template>
  <div>
    <v-progress-circular
      v-if="loading"
      indeterminate
      color="grey darken-2"
      size=24
    ></v-progress-circular>

    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="date"
      transition="scale-transition"
      offset-y
      min-width="auto"
      :disabled="disabled"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :hidden="loading"
          dark
          dense
          readonly
          rounded
          v-bind="attrs"
          v-on="on"
          :value="humanReadableDate"
          class="centered-v-text-field pa-0 ma-0"
          :class="[color, completedDate ? 'completed': '']"
          style="border-radius: 99px; font-size: 12px; max-width: 115px;"
          hide-details
        >
        </v-text-field>
      </template>

      <v-date-picker v-model="date" no-title scrollable>
        <v-spacer></v-spacer>

        <v-btn text color="red" @click="setNewDate(clear=true)">Clear</v-btn>
        <v-btn text color="primary" @click="setNewDate()">OK</v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import moment from "moment";
import {v4 as uuidv4} from 'uuid';

export default {
  name: "PlanningToolDatePicker",
  props: [
    // Can expect null or Date
    'due-date',
    'completed-date',
    'slug',
    'id',
    'field',
    'disabled',
    'greyed-out',
    'project-code'
  ],
  data() {
    return {
      // Assuming this is correct
      currentDateObject: new Date(moment(new Date()).format('YYYY-M-DD')),
      menu: false,
      color: 'grey',
      // Date variable for use in the component
      date: this.dueDate,
      loading: false
    };
  },
  watch: {
    dueDate() {
      this.date = this.dueDate;

      this.calculateColor();
    }
  },
  methods: {
    setNewDate(clear=false) {
      let this_ = this;

      this.loading = true;

      // Save new date
      let newDate = this.date;

      if (clear) {
        newDate = null;
      }

      // Hide menu
      this.menu = false;

      let data = {};

      if (this.field === 'final_delivery_date') {
        data = {
          final_delivery_date: newDate
        }
      } else {
        data = {
          delivery_date: newDate
        }
      }

      this.$http
        .patch(
          this.$store.state.apiUrl +
            "/" +
            this.$store.state.departmentText +
            "/" +
            this.slug +
            "/" +
            this.id,
          data,
          {
            headers: { Authorization: this.$store.getters.apiToken },
          }
        )
        .then(() => {
          // TODO: Bind to final delivery date, or delivery date
          this_.date = newDate

          this_.$refs.menu.save(newDate);

          let projectIndex = this_.$store.getters.projects.findIndex(function (i) {
            return i.project_code === this_.projectCode;
          });

          if (this_.field === 'final_delivery_date') {
            this_.$store.state.projects[projectIndex].final_delivery_date = newDate;
          } else {
            let processIndex = this_.$store.getters.projects[projectIndex].processes.findIndex(function (i) {
              return i.id === this_.id;
            });

            this_.$store.state.projects[projectIndex].processes[processIndex].delivery_date = newDate
          }

          this_.loading = false;

          this.calculateColor();
        })
        .catch((e) => {
          this.loading = false;

          if ('response' in e && e.response.status === 401) {
            this.$store.state.apiToken = null
            // Set local storage variable
            localStorage.setItem('token', null)
          }

          // No date update happened
          this_.$store.state.errors.push({
            id:  uuidv4(),
            message: 'Oops, unable to update delivery date! Please log a helpdesk query.'
          });

          this.calculateColor();
        });
    },
    calculateColor() {
      if (!this.date || this.greyedOut) {
        this.color = 'grey';
        return;
      }

      let checkDate = this.currentDateObject;
      
      if (this.completedDate) {
        // Completed scenario override
        checkDate = this.completedDateObject;
      }

      if (checkDate > this.dueDateObject) {
        this.color = 'red';
      } else {
        this.color = 'green';
      }
    }
  },
  computed: {
    completedDateObject() {
      return new Date(moment(new Date(this.completedDate)).format('YYYY-M-DD'));
    },
    dueDateObject() {
      return new Date(moment(new Date(this.date)).format('YYYY-M-DD'));
    },
    humanReadableDate() {
      // If null is passed or dueDate cleared
      if (!this.date) {
        return 'TBC';
      }

      return this.dueDateObject ? moment(this.dueDateObject).format("ddd DD MMM") : '';
    },
  },
  created() {
    this.calculateColor();
  }
};
</script>

<style scoped>
.centered-v-text-field >>> input {
  text-align: center;
}

.completed >>> input {
  text-decoration: line-through;
}
</style>