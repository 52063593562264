<template>
  <v-btn @click="refreshData" :loading="refreshingData" color="success" tile>
    Refresh
    <v-icon right> mdi-refresh </v-icon>
  </v-btn>
</template>

<script>
import { mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "PlanningToolRefreshDataButton",
  computed: {
    ...mapGetters([
      'refreshingData'
    ])
  },
  methods: {
    refreshData() {
      this.$store.state.refreshingData = true;
      let this_ = this;

      this.$http
        .post(this.$store.state.apiUrl + "/" + this.$store.state.departmentText + "/" + "refresh_data", {}, {
          headers: { Authorization: this.$store.getters.apiToken },
        })
        .then(() => {
          this.$store.state.refreshingData = false;
          this.$store.state.refreshData = true;
        })
        .catch((e) => {
          if ('response' in e && e.response.status === 401) {
            this.$store.state.apiToken = null
            // Set local storage variable
            localStorage.setItem('token', null)
          }

          this_.$store.state.errors.push({
            id:  uuidv4(),
            message: 'Oops, unable to refresh data! Please log a helpdesk query.'
          });

          // TODO: Better error handling
          this.$store.state.refreshingData = false;
        });
    },
  },
};
</script>