<template>
  <div>
    <br />
    <v-card
      elevation="0"
      outline="true"
    >
      <v-btn
        v-for="department in departments"
        :key="department.id"
        color="blue"
        tile
        :height="height"
        :width="width"
        dark
        @click="navigateToDepartment(department.name)"
      >
        {{ department.name }}
      </v-btn>

      <v-btn
        color="blue"
        tile
        :height="height"
        :width="width"
        dark
        @click="navigateToDepartment('stock-checker')"
      >
        Stock Checker
      </v-btn>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PlanningToolChooseDepartment",
  data () {
    return {
      height: '200px',
      width: '200px'
    }
  },
  methods: {
    navigateToDepartment(departmentName) {
      this.$router.push('/' + departmentName)
    }
  },
  computed: {
    ...mapGetters([
      'departments'
    ])
  },
  created () {
    this.$store.state.departmentText = ''
  }
};
</script>