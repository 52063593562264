<template>
  <v-combobox
    @change="saveChanges"
    style="background-color: white"
    v-model="chips"
    :items="assemblers"
    chips
    clearable
    label="Prototype Members"
    multiple
    class="ma-0 pl-2 pr-2"
  >
    <template v-slot:selection="{ attrs, item, select, selected }">
      <v-chip
        small
        v-bind="attrs"
        :input-value="selected"
        close
        @click="select"
        @click:close="remove(item)"
        class="mt-2"
      >
        {{ item.text }}
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
import { mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";

export default {
  data() {
    return {
      chips: [],
      savedChips: [],
      members: []
    };
  },
  props: ["project"],
  computed: {
    ...mapGetters(["assemblers"]),
  },
  watch: {
    project() {
      this.update()
    }
  },
  created() {
    this.savedChips = this.chips
    this.update()
  },
  methods: {
    saveChanges() {
      // Detect if a delete or add change happened
      let chipsCount = this.chips.length
      let savedChipsCount = this.savedChips.length

      let longestList = []
      let shortestList = []

      let differenceList = []

      let add = false

      if (chipsCount > savedChipsCount) {
        // Add
        if (this.chips.length > 0) {
          longestList = [...this.chips]
        } else {
          longestList = []
        }

        if (this.saveChanges.length > 0) {
          shortestList = [...this.saveChanges]
        } else {
          shortestList = []
        }
        add = true
      } else if (savedChipsCount > chipsCount) {
        // Remove
        if (this.savedChips.length > 0) {
          longestList = [...this.savedChips]
        } else {
          longestList = []
        }

        if (this.chips.length > 0) {
          shortestList = [...this.chips]
        } else {
          shortestList = []
        }
      }
      
      var BreakException = {};

      longestList.forEach((first_list_element) => {
        let contained = false

        try {
          shortestList.forEach(second_list_element => {
            if (first_list_element.fullName === second_list_element.fullName) {
              contained = true;
              throw BreakException;
            }
          });
        } catch (e) {
          if (e !== BreakException) throw e;
        }

        if (contained === false) {
          differenceList.push(first_list_element)
        }
      })

      if (add === true) {
        // REMOVE ME
        differenceList.forEach((element) => {
          this.$store.state.trelloInstance.post('/cards/' + this.project.trello_card_id_proto + '/idMembers/?value=' + element.id,
            () => {
              this.savedChips = this.chips

              this.update()
            },
            () => {
              this.update()
            })
        })
      } else {
        // REMOVE ME
        differenceList.forEach((element) => {
          this.$store.state.trelloInstance.delete('/cards/' + this.project.trello_card_id_proto + '/idMembers/' + element.id,
            () => {
              this.savedChips = this.chips

              this.update()
            },
            () => {
              this.update()
            })
        })
      }
    },
    remove(item) {
      this.members.forEach((element) => {
        if (element.fullName === item.fullName) {
          this.$store.state.trelloInstance.delete('/cards/' + this.project.trello_card_id_proto + '/idMembers/' + element.id,
          () => {
            this.savedChips = this.chips

            this.update()
          },
          () => {
            this.update()
          })
        }
      })
    },
    update() {
      this.$store.state.trelloInstance.cards.get(this.project.trello_card_id_proto + '/members',
        (response) => {
          this.members = response
          this.chips = []

          response.forEach(element => {
            element.text = element.fullName
            this.chips.push(element)
          });
        },
        () => {
          this.$store.state.errors.push({
              id:  uuidv4(),
              message: 'Oops, Error fetching card members for - ' + this.project.trello_card_id_proto
          })
        }
      )
    },
  },
};
</script>
