<template>
  <v-combobox
    @change="saveChanges"
    style="background-color: white"
    v-model="chips"
    :items="possibleLabels"
    chips
    clearable
    label="Labels"
    multiple
    class="ma-0 pl-2 pr-2"
  >
    <template v-slot:selection="{ attrs, item, select, selected }">
      <v-chip
        small
        v-bind="attrs"
        :input-value="selected"
        close
        @click="select"
        @click:close="remove(item)"
        class="mt-2"
      >
        {{ item }}
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
import { mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";

export default {
  data() {
    return {
      chips: [],
    };
  },
  props: ["project-code"],
  computed: {
    ...mapGetters(["possibleLabels"]),
  },
  watch: {
    projectCode() {
      this.update();
    },
  },
  created() {
    this.update();
  },
  methods: {
    saveChanges() {
      let chipsData = [];
      let this_ = this;

      this.chips.forEach((chip) => {
        chipsData.push({ tag: chip });
      });

      this.$http
        .patch(
          this.$store.state.apiUrl +
            "/" +
            this.$store.state.departmentText +
            "/" +
            "project_tag" +
            "/" +
            this.projectCode,
          chipsData,
          {
            headers: { Authorization: this.$store.getters.apiToken },
          }
        )
        .catch((e) => {
          if ('response' in e && e.response.status === 401) {
            this.$store.state.apiToken = null
            // Set local storage variable
            localStorage.setItem('token', null)
          }

          // TODO: Add loader maybe? and remove failed labels logic
          this_.$store.state.errors.push({
            id: uuidv4(),
            message:
              "Oops, could not update the assigned to field for " +
              this.projectCode,
          });
        });
    },
    remove(item) {
      this.chips.splice(this.chips.indexOf(item), 1);
      this.chips = [...this.chips];
      this.saveChanges();
    },
    update() {
      this.chips = [];
      let this_ = this;

      if (this.chips.length === 0) {
        this.$http
          .get(
            this.$store.state.apiUrl +
              "/" +
              this.$store.state.departmentText +
              "/" +
              "project_tag" +
              "?project=" +
              this.projectCode,
            {
              headers: { Authorization: this.$store.getters.apiToken },
            }
          )
          .then((response) => {
            response.data.forEach((project_tag) => {
              this.chips.push(project_tag.tag);
            });
          })
          .catch((e) => {
            if ('response' in e && e.response.status === 401) {
              this.$store.state.apiToken = null
              // Set local storage variable
              localStorage.setItem('token', null)
            }

            this_.$store.state.errors.push({
              id:  uuidv4(),
              message: 'Oops, unable to update assigned to values! Please log a helpdesk query.'
            });
          });
      }
    },
  },
};
</script>
