import StockChecker from './components/content/StockChecker.vue'
import DepartmentContent from './components/content/DepartmentContent.vue'
import ErrorNotFound from './components/base/errors/ErrorNotFound.vue'
import Home from './components/base/Home.vue'

export const routes = [
  { path: '', component: Home },
  { path: '/stock-checker', component: StockChecker, props: true },
  { path: '/:department', component: DepartmentContent, props: true },
  { path: '*', component: ErrorNotFound }
]