<template>
  <div>
    <v-card elevation="2">
      <v-card-title>Oops | This Department Could Not Be Found</v-card-title>
      <v-card-subtitle
        >Please view possible options below to navigate to where you want to
        get.</v-card-subtitle
      >
    </v-card>

    <choose-department></choose-department>
  </div>
</template>

<script>
import ChooseDepartment from "../department/ChooseDepartment.vue";

export default {
  name: "ErrorNotFound",
  components: {
    "choose-department": ChooseDepartment,
  },
};
</script>