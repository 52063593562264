<template>
  <div>
    <v-data-iterator
      v-if="!loading && $store.getters.projects.length > 0"
      :items="items"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="search"
      :sort-by="sortBy.toLowerCase()"
      :sort-desc="sortDesc"
      hide-default-footer
      :custom-sort="planningCardSort"
    >
      <template v-slot:header>
        <options>
          <template v-slot:filter>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="search"
                    clearable
                    light
                    prepend-inner-icon="mdi-magnify"
                    label="Search"
                  ></v-text-field>
                </v-col>

                <v-col>
                  <v-select
                    v-model="sortBy"
                    :items="sortKeys"
                    item-text="name"
                    item-value="value"
                    prepend-inner-icon="mdi-magnify"
                    label="Sort by"
                    light
                  ></v-select>
                </v-col>

                <v-col>
                  <v-btn-toggle tile v-model="sortDesc" mandatory>
                    <v-btn large color="primary" :value="false">
                      <v-icon>mdi-arrow-up</v-icon>
                    </v-btn>
                    <v-btn large color="primary" :value="true">
                      <v-icon>mdi-arrow-down</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </options>
      </template>

      <template v-slot:default="props">
        <br />
        <v-row>
          <v-col
            class="col-lg-2 col-md-3 col-sm-6 col-12"
            v-for="project in props.items"
            :key="project.id"
          >
            <planning-tool-card :project="project"></planning-tool-card>
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <v-container fluid>
          <v-row class="mt-2" align="center" justify="center">
            <span class="grey--text">Planning cards per page</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  text
                  color="primary"
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ itemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(number, index) in itemsPerPageArray"
                  :key="index"
                  @click="updateItemsPerPage(number)"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-spacer></v-spacer>

            <span class="mr-4 grey--text">
              Page {{ page }} of {{ numberOfPages }}
            </span>
            <v-btn
              fab
              text
              dark
              color="secondary darken-4"
              class="mr-1"
              @click="formerPage"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              fab
              dark
              color="secondary darken-4"
              class="ml-1"
              @click="nextPage"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </v-container>
      </template>
    </v-data-iterator>

    <v-row v-if="!loading && $store.getters.projects.length === 0">
      <v-col>
        <div style="width: 100%" class="text-center">
          <br />
          <p>No planning cards found! Try refreshing the page.</p>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="loading">
      <v-col>
        <div style="width: 100%" class="text-center">
          <br />
          <v-progress-circular
            indeterminate
            color="grey darken-2"
          ></v-progress-circular>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Card from "./card/Card.vue";
import { mapGetters } from "vuex";
import Options from "./options/Options.vue";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "PlanningToolCards",
  data() {
    return {
      loading: false,
      itemsPerPageArray: [25, 50, 100, 150, 500, 1000],
      search: "",
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 25,
      sortBy: "final_delivery_date",
      keys: [
        "project_code",
        "name",
        "description",
        "department",
        "image_url",
        "final_delivery_date"
      ],
      sortKeys: [
        { name: "Job Number", value: "project_code" },
        { name: "Description", value: "name" },
        { name: "Final Delivery Date", value: "final_delivery_date" },
      ],
      items: [],
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.keys.filter((key) => key !== "Name");
    },
    ...mapGetters(["refreshData", "projects"]),
  },
  components: {
    "planning-tool-card": Card,
    Options,
  },
  watch: {
    refreshData() {
      this.updateProjects();

      this.$store.state.refreshData = false;
    },
  },
  methods: {
    planningCardSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] == "final_delivery_date") {
          if (!isDesc[0]) {
            return new Date(b[index]) - new Date(a[index]);
          } else {
            return new Date(a[index]) - new Date(b[index]);
          }
        } else {
          if (typeof a[index] !== "undefined") {
            if (!isDesc[0]) {
              return a[index]
                .toLowerCase()
                .localeCompare(b[index].toLowerCase());
            } else {
              return b[index]
                .toLowerCase()
                .localeCompare(a[index].toLowerCase());
            }
          }
        }
      });
      return items;
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    updateProjects() {
      this.loading = true;
      let this_ = this;

      this.$http
        .get(
          this.$store.state.apiUrl +
            "/" +
            this.$store.state.departmentText +
            "/" +
            "planning_cards",
          {
            headers: { Authorization: this.$store.getters.apiToken },
          }
        )
        .then((response) => {
          this.$store.state.projects = response.data;
          this.items = response.data;

          this.loading = false;
        })
        .catch((e) => {
          if ('response' in e && 'response' in e && e.response.status === 401) {
            this.$store.state.apiToken = null
            // Set local storage variable
            localStorage.setItem('token', null)
          } else {
            this_.$store.state.errors.push({
              id:  uuidv4(),
              message: 'Oops, unable to load planning cards! Please try to refresh the browser, or log a helpdesk query.'
            });
          }

          this.loading = false;
        });
    },
  },
  created() {
    this.updateProjects();
  },
};
</script>