<template>
  <v-expansion-panels
     v-model="panels"
     multiple
     dark
  >
    <v-expansion-panel>
      <v-expansion-panel-header color="primary">
        Filter Options
      </v-expansion-panel-header>

      <v-expansion-panel-content color="white">
        <slot name="filter"></slot>
      </v-expansion-panel-content>
    </v-expansion-panel>
    
    <!-- TODO: Implement -->
    <!-- <view-switcher></view-switcher> -->
  </v-expansion-panels>
</template>

<script>
export default {
  name: "PlanningToolOptions",
  data() {
    return {
      // Start the expansion panel as open or active
      panels: [
        0
      ],
    }
  }
};
</script>