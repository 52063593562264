import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'

import { routes } from './routes.js'
import { store } from './store'

import Axios from 'axios'

Vue.prototype.$http = Axios;

import vuetify from '@/plugins/vuetify'

import Header from './components/base/Header.vue'
import Footer from './components/base/Footer.vue'
import RefreshButton from './components/base/RefreshButton.vue'

Vue.config.productionTip = false;

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes
});

Vue.component('planning-tool-header', Header);
Vue.component('planning-tool-footer', Footer);
Vue.component('planning-tool-refresh-data', RefreshButton);

new Vue({
  router,
  vuetify,
  render: h => h(App),
  store
}).$mount('#app');
