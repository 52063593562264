<template>
  <v-footer padless app>
    <v-alert
      @input="errorDismissed(errorObject.id)"
      :hidden="errors.length > 0 ? true : false"
      v-for="errorObject in errors"
      :key="errorObject.id"
      type="error"
      tile
      dismissible
      style="width: 100%"
      dense
      class="ma-0"
    >
      {{ errorObject.message }}
    </v-alert>
    <v-col
      class="text-center"
      cols="12"
    >
      © {{ new Date().getFullYear() }} Barrows Software | Planning Tool
    </v-col>
  </v-footer>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'errors'
    ])
  },
  methods: {
    errorDismissed(id_) {
      this.errors.splice(this.errors.findIndex(function(i) {
          return i.id === id_;
      }), 1);
    }
  }
}
</script>