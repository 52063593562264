<template>
  <div>
    <div
      v-if="loading"
      style="background-color: white; width: 100%"
      class="text-center"
    >
      <br />
      <v-progress-circular
        indeterminate
        color="grey darken-2"
      ></v-progress-circular>
    </div>
    <div v-else style="background-color: white">
      <v-combobox
        @keyup.enter="addProjectProcess"
        class="ma-0"
        label="Add Process"
        style="padding-left: 10px; padding-right: 10px"
        v-model="newProcessText"
        dense
        :items="possibleProcesses"
      >
        <template v-slot:append>
          <v-btn
            :loading="addProjectProcessLoading"
            fab
            x-small
            text
            @click="addProjectProcess"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
      </v-combobox>
    </div>

    <p
      style="background-color: white"
      class="pr-2 pl-2 pb-2 ma-0"
      v-if="processes.length === 0 && !loading"
    >
      No processes found!
    </p>

    <v-list class="pt-0" v-else style="background-color: white">
      <v-list-item
        dense
        v-for="projectProcess in processes"
        :key="projectProcess.id"
        class="pl-2"
      >
        <template>
          <v-list-item-action class="mr-3">
            <v-progress-circular
              v-if="
                completeProjectProcessLoading &&
                projectProcess.id === completeProjectProcessIdClicked
              "
              indeterminate
              color="grey darken-2"
              size="24"
            ></v-progress-circular>
            <v-checkbox
              v-else
              @click="
                completeProjectProcess(
                  projectProcess.id,
                  projectProcess.completed
                )
              "
              dense
              :ripple="false"
              v-model="projectProcess.completed"
            ></v-checkbox>
          </v-list-item-action>

          <v-list-item-content class="pa-1">
            <v-list-item-title
              :class="projectProcess.completed ? 'completed' : ''"
            >
              {{ projectProcess.display_name }}
            </v-list-item-title>

            <v-list-item-subtitle>
              <date-picker
                :due-date="projectProcess.delivery_date"
                :completed-date="
                  projectProcess.completed_at_date
                    ? projectProcess.completed_at_date
                    : null
                "
                :id="projectProcess.id"
                slug="update_card_process_delivery_date"
                field="delivery_date"
                :project-code="projectCode"
              ></date-picker>
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-btn
            @click="removeProjectProcess(projectProcess.id)"
            fab
            text
            x-small
            :loading="
              removeProjectProcessLoading &&
              removeProjectProcessIdClicked === projectProcess.id
            "
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import DatePicker from "../../base/date/PlanningToolDatePicker.vue";
import { v4 as uuidv4 } from "uuid";
import { mapGetters } from 'vuex';

export default {
  name: "PlanningToolProcesses",
  components: {
    DatePicker,
  },
  props: ["processes", "project-code"],
  data() {
    return {
      loading: false,
      newProcessText: null,
      addProjectProcessLoading: false,
      removeProjectProcessLoading: false,
      removeProjectProcessIdClicked: null,
      completeProjectProcessLoading: false,
      completeProjectProcessIdClicked: null,
      rules: {
        required: (value) => !!value || "Required",
      },
    };
  },
  computed: {
    ...mapGetters(["possibleProcesses"]),
  },
  methods: {
    removeProjectProcess(projectProcessId) {
      this.removeProjectProcessIdClicked = projectProcessId;
      this.removeProjectProcessLoading = true;
      let this_ = this;

      this.$http
        .delete(
          this.$store.state.apiUrl +
            "/" +
            this.$store.state.departmentText +
            "/" +
            "project-process" +
            "/" +
            projectProcessId,
          {
            headers: { Authorization: this.$store.getters.apiToken },
          }
        )
        .then((response) => {
          let projectIndex = this_.$store.getters.projects.findIndex(function (i) {
            return i.project_code === this_.projectCode;
          });

          this_.$store.state.projects[projectIndex].processes.splice(
            this_.$store.getters.projects[projectIndex].processes.findIndex(function (i) {
              return i.id === response.data.id;
            }),
            1
          );

          this.removeProjectProcessLoading = false;
        })
        .catch((e) => {
          if ('response' in e && e.response.status === 401) {
            this_.$store.state.apiToken = null;
            // Set local storage variable
            localStorage.setItem("token", null);
          }

          this_.$store.state.errors.push({
            id:  uuidv4(),
            message: 'Oops, unable to remove process from the project! Please log a helpdesk query.'
          });

          this.removeProjectProcessLoading = false;
        });
    },
    completeProjectProcess(projectProcessId, state) {
      this.completeProjectProcessIdClicked = projectProcessId;
      this.completeProjectProcessLoading = true;
      let this_ = this;

      this.$http
        .patch(
          this.$store.state.apiUrl +
            "/" +
            this.$store.state.departmentText +
            "/" +
            "project-process" +
            "/" +
            projectProcessId,
          {
            completed: state,
          },
          {
            headers: { Authorization: this.$store.getters.apiToken },
          }
        )
        .then((response) => {
          let projectIndex = this_.$store.getters.projects.findIndex(function (i) {
            return i.project_code === this_.projectCode;
          });

          let foundIndex = this.processes.findIndex(function (i) {
            return i.id === response.data.id;
          });

          this_.$store.state.projects[projectIndex].processes[foundIndex].completed = response.data.completed;
          this_.$store.state.projects[projectIndex].processes[foundIndex].completed_at_date =
            response.data.completed_at_date;

          this.completeProjectProcessLoading = false;
        })
        .catch((e) => {
          if ('response' in e && e.response.status === 401) {
            this_.$store.state.apiToken = null;
            // Set local storage variable
            localStorage.setItem("token", null);
          }

          let projectIndex = this_.$store.getters.projects.findIndex(function (i) {
            return i.project_code === this_.projectCode;
          });

          let foundIndex = this.processes.findIndex(function (i) {
            return i.id === projectProcessId;
          });

          this_.$store.state.projects[projectIndex].processes[foundIndex].completed = !state
            null;

          this.completeProjectProcessLoading = false;

          this_.$store.state.errors.push({
            id:  uuidv4(),
            message: 'Oops, unable to complete project process! Please log a helpdesk query.'
          });
        });
    },
    addProjectProcess() {
      this.addProjectProcessLoading = true;
      // Create new process
      let processData = {
        name: this.newProcessText,
        display_name: this.newProcessText,
      };

      let this_ = this;

      this.$http
        .post(
          this.$store.state.apiUrl +
            "/" +
            this.$store.state.departmentText +
            "/" +
            "process",
          processData,
          {
            headers: { Authorization: this.$store.getters.apiToken },
          }
        )
        .then((processResponse) => {
          let projectProcessData = {
            process: processResponse.data.id,
            project: this_.projectCode,
          };

          // Bind the new process and project
          this.$http
            .post(
              this.$store.state.apiUrl +
                "/" +
                this.$store.state.departmentText +
                "/" +
                "project-process",
              projectProcessData,
              {
                headers: { Authorization: this.$store.getters.apiToken },
              }
            )
            .then((projectProcessResponse) => {
              projectProcessResponse.data.process = processResponse.data;

              let projectIndex = this_.$store.getters.projects.findIndex(function (i) {
                return i.project_code === this_.projectCode;
              });

              this_.$store.state.projects[projectIndex].processes.push({
                id: projectProcessResponse.data.id,
                name: projectProcessResponse.data.process.name,
                display_name: projectProcessResponse.data.process.display_name,
                delivery_date: projectProcessResponse.data.delivery_date,
                completed: projectProcessResponse.data.completed,
                completed_at_date: projectProcessResponse.data.completed_at_date
              });

              this.addProjectProcessLoading = false;
              this.newProcessText = "";
            })
            .catch((e) => {
              if ('response' in e && e.response.status === 401) {
                this_.$store.state.apiToken = null;
                // Set local storage variable
                localStorage.setItem("token", null);
              }

              this_.$store.state.errors.push({
                id:  uuidv4(),
                message: 'Oops, unable to bind the new process to the project [2/2]! Please log a helpdesk query.'
              });

              this.addProjectProcessLoading = false;
              // TODO: Add loading
            });
        })
        .catch((e) => {
          if ('response' in e && e.response.status === 401) {
            this_.$store.state.apiToken = null;
            // Set local storage variable
            localStorage.setItem("token", null);
          }

          this_.$store.state.errors.push({
            id:  uuidv4(),
            message: 'Oops, unable to add the new process [1/2]! Please log a helpdesk query.'
          });

          this.addProjectProcessLoading = false;
        });
    }
  },
};
</script>

<style scoped>
.completed {
  text-decoration: line-through;
}
</style>