<template>
  <!-- Apply container -->
  <div v-if="validDepartment">
    <br />
    <cards></cards>
  </div>
  <department-not-found v-else></department-not-found>
</template>

<script>
import ErrorDepartmentNotFound from "../base/errors/ErrorDepartmentNotFound";
import { mapGetters } from "vuex";
import Cards from "./PlanningToolCards.vue";

export default {
  name: "PlanningToolDepartmentContent",
  props: ["department"],
  components: {
    "department-not-found": ErrorDepartmentNotFound,
    Cards,
  },
  watch: {
    department() {
      this.validateClientDepartment();
    },
  },
  data() {
    return {
      departmentRequested: "",
      validDepartment: false,
    };
  },
  methods: {
    validateClientDepartment() {
      this.departmentRequested = this.department;
      let departmentsFlat = [];

      this.departments.forEach((element) => {
        departmentsFlat.push(element.name);
      });

      if (departmentsFlat.includes(this.departmentRequested)) {
        this.validDepartment = true;
        // TODO: Use ID rather, instead of text
        this.$store.state.departmentText = this.departmentRequested;
      } else {
        this.validDepartment = false;
      }
    },
  },
  created() {
    this.validateClientDepartment();
  },
  computed: {
    ...mapGetters(["departments"]),
  },
};
</script>