import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'

Vue.use(Vuex)

console.log(process.env)

// TODO: Split into different store files
export const store = new Vuex.Store({
    state: {
        apiUrl: process.env.VUE_APP_API_URL,
        loginApiUrl: process.env.VUE_APP_LOGIN_API_URL,
        prototypingBoardID: process.env.VUE_APP_PROTOTYPING_BOARD_ID,
        fabricationBoardID: process.env.VUE_APP_FABRICATION_BOARD_ID,
        appName: "planning tool",
        departmentText: "",
        departments: [],
        projects: [],
        errors: [],
        loginRequest: false,
        possibleLabels: [],
        fabricators: [],
        assemblers: [],
        possibleProcesses: [],
        refreshData: false,
        refreshingData: false,
        apiToken: "",
        trelloInstance: window.Trello,
        placeholderImage: "https://upload.wikimedia.org/wikipedia/commons/6/6c/Relation_0000_0000_%28cubic_matrix%29.png"
    },
    getters: {
        trelloInstance: state => {
            return state.trelloInstance;
        },
        title: state => {
            let displayDepartmentText = state.departmentText;

            if (state.departmentText) {
                displayDepartmentText = ' | ' + state.departmentText;
            }

            return state.appName + displayDepartmentText;
        },
        departments: state => {
            // Check if departments already fetched
            if (state.departments.length === 0) {
                // TODO: Use actual API here, remember authentication and authorization
                state.departments = [
                    {
                        id: 1,
                        name: 'prototyping'
                    }
                ]
            }

            return state.departments
        },
        projects: state => {
            return state.projects
        },
        errors: state => {
            return state.errors
        },
        refreshData: state => {
            return state.refreshData;
        },
        refreshingData: state => {
            return state.refreshingData;
        },
        apiToken: state => {
            let possibleFetchedToken = localStorage.getItem('token');

            if (possibleFetchedToken && possibleFetchedToken !=='null') {
                state.apiToken = possibleFetchedToken;
            }

            if (!state.apiToken || state.apiToken === 'null') {
                state.loginRequest = true;
                return
            }

            return state.apiToken
        },
        loginRequest: state => {
            return state.loginRequest;
        },
        possibleProcesses: state => {
            if (state.possibleProcesses.length === 0 && state.departmentText) {
                Axios
                    .get(state.apiUrl + "/" + state.departmentText + "/" + "process", {
                        headers: { Authorization: state.apiToken },
                    })
                    .then((response) => {
                        response.data.forEach(process => {
                            state.possibleProcesses.push(process.display_name);
                        });
                    })
                    .catch((e) => {
                        if ('response' in e && e.response.status === 401) {
                            state.apiToken = null
                            // Set local storage variable
                            localStorage.setItem('token', null)
                        }

                        console.log('Oops, could not retrieve processes! Please log a helpdesk query.')
                    });
            }

            return state.possibleProcesses
        },
        possibleLabels: state => {
            if (state.possibleLabels.length === 0 && state.departmentText) {
                Axios
                    .get(state.apiUrl + "/" + state.departmentText + "/" + "tag", {
                        headers: { Authorization: state.apiToken },
                    })
                    .then((response) => {
                        response.data.forEach(label => {
                            state.possibleLabels.push(label.tag_name);
                        });
                    })
                    .catch((e) => {
                        if ('response' in e && e.response.status === 401) {
                            state.apiToken = null
                            // Set local storage variable
                            localStorage.setItem('token', null)
                        }
                        
                        console.log('Oops, could not retrieve labels! Please log a helpdesk query.');
                    });
            }

            return state.possibleLabels
        },
        assemblers: state => {
            if (state.assemblers.length === 0 && state.departmentText) {
                state.trelloInstance.get('/boards/' + state.prototypingBoardID + '/members', (response) => {
                    state.assemblers = response

                    state.assemblers.forEach(element => {
                        element.text = element.fullName
                    })
                })
            }

            return state.assemblers
        },
        fabricators: state => {
            if (state.fabricators.length === 0 && state.departmentText) {
                state.trelloInstance.get('/boards/' + state.fabricationBoardID + '/members', (response) => {
                    state.fabricators = response
                    
                    state.fabricators.forEach(element => {
                        element.text = element.fullName
                    })
                })
            }

            return state.fabricators
        }
    }
})