<template>
  <v-card :tile="true" elevation="3" color="grey lighten-2">
    <div style="min-height: 125px;">
      <v-card-title class="pb-2 pt-1 pl-2 pr-2">
        {{ project.project_code }}
      </v-card-title>
      <v-card-subtitle class="pb-2 pt-1 pl-2 pr-2">
        {{ project.name }}
      </v-card-subtitle>


      <v-card-text class="pb-2 pt-1 pl-2 pr-2" dense>
        <date-picker
          :due-date="project.final_delivery_date"
          :completed-date="
            project.completed_at_date ? projectProcess.completed_at_date : null
          "
          :id="project.project_code"
          :project-code="project.project_code"
          slug="update_card_final_delivery_date"
          field="final_delivery_date"
        ></date-picker>

        <div style="margin-top: 10px;">
          <a :href="project.google_drive_link" target="_blank">Drawing</a>
        </div>
      </v-card-text>
    </div>

    <v-img
      max-height="150"
      :contain="true"
      :alt="'Project image URL: ' + project.image_url"
      :src="
        project.image_url ? project.image_url : $store.state.placeholderImage
      "
      style="background-color: white"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey darken-2"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <processes :project-code="project.project_code" :processes="project.processes"></processes>

    <labels :project-code="project.project_code"></labels>
    
    <fabricators v-if="project.trello_card_id_proto_fab !== null" :project="project"></fabricators>
    
    <assemblers v-if="project.trello_card_id_proto !== null" :project="project"></assemblers>

    <view-orders :project-code="project.project_code"></view-orders>

    <!-- TODO: Set up all errors -->
  </v-card>
</template>

<script>
import Labels from "./Labels";
import Assemblers from "./Assemblers";
import Fabricators from "./Fabricators";
import ViewOrders from "./ViewOrders.vue";
import Processes from "./PlanningToolProcesses.vue";
import DatePicker from "../../base/date/PlanningToolDatePicker.vue";

export default {
  name: "PlanningToolCard",
  data() {
    return {
      dialog: false,
    };
  },
  components: {
    Processes,
    DatePicker,
    ViewOrders,
    Labels,
    Fabricators,
    Assemblers
  },
  props: ["project"]
};
</script>