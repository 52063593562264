<template>
  <div>
    <v-card elevation="2">
      <v-card-title>Welcome to the best planning tool ever! Built with love from team Pioneers.</v-card-title>
      <v-card-subtitle
        >Please select a department you want to visit.</v-card-subtitle
      >
    </v-card>
    <choose-department></choose-department>
  </div>
</template>

<script>
import ChooseDepartment from "./department/ChooseDepartment.vue";

export default {
  name: "ErrorNotFound",
  components: {
    "choose-department": ChooseDepartment,
  },
};
</script>