<template>
  <div>
    <v-app-bar app color="secondary-4" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      
      <!-- TODO: Link up to vuex -->
      <!-- TODO: Add teal color split -->
      <a class="white--text">
        <v-toolbar-title @click="$router.push('/')" style="text-transform: capitalize;">{{ $store.getters.title }}</v-toolbar-title>
      </a>

      <v-spacer></v-spacer>

      <planning-tool-refresh-data v-if="$store.state.departmentText"></planning-tool-refresh-data>
    </v-app-bar>

    <v-navigation-drawer color="secondary-4" dark app v-model="drawer">
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="text--accent-4"
        >
          <v-list-item v-if="$store.state.departmentText">
            <v-list-item-title>
              <planning-tool-refresh-data style="width: 100%;"></planning-tool-refresh-data>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
  export default {
    name: "PlanningToolFooter",
    data: () => ({
      drawer: false,
      group: null,
    }),

    watch: {
      group () {
        this.drawer = false
      },
    }
  }
</script>