<template>
  <div>
    <h2>Stock Checker</h2>
    
    <br />
  
    <v-layout align-center justify-center column>
      <v-flex row align-center>
        <v-progress-circular
          style="text-align: center"
          v-if="loading"
          indeterminate
          color="grey darken-2"
        ></v-progress-circular>
      </v-flex>
    </v-layout>

    <p v-if="stockLines.length === 0 && loading === false">
      No stock lines to display!
    </p>

    <p v-if="stockLines.length > 0 && loading === false">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-data-table :headers="headers" :items="stockLines" :search="search">
      </v-data-table>
    </p>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
  data () {
    return {
      stockLines: [],
      dialog: false,
      search: "",
      headers: [
        {
          text: "PRODUCT CODE",
          align: "start",
          filterable: true,
          value: "PRODUCT CODE",
        },
        {
          text: "DESCRIPTION",
          align: "start",
          filterable: true,
          value: "DESCRIPTION",
        },
        {
          text: "SITE",
          align: "start",
          filterable: true,
          value: "SITE",
        },
        {
          text: "ALLOCATED",
          align: "start",
          filterable: true,
          value: "ALLOCATED",
        },
        {
          text: "AVAILABLE QTY",
          align: "start",
          filterable: true,
          value: "QTY",
        }
      ],
      loading: false,
    }
  },
  created() {
    this.getStockLines();
  },
  methods: {
    getStockLines() {
      this.loading = true;
      this.stockLines = [];
      let this_ = this;

      this.$http
        .get(
          this.$store.state.loginApiUrl +
            "/api/stock_checker",
          {
            headers: { Authorization: this.$store.getters.apiToken },
          }
        )
        .then((response) => {
          this.stockLines = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this_.$store.state.errors.push({
            id: uuidv4(),
            message:
              "Oops, could not retrieve the stock lines"
          });

          this.loading = false;

          if ('response' in e && e.response.status === 401) {
            this.$store.state.apiToken = null
            // Set local storage variable
            localStorage.setItem('token', null)
          }
        });
    }
  }
};
</script>