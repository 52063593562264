<template>
  <!-- Split into its own component -->
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        tile
        style="width: 100%"
        @click="getOrderLines"
      >
        View Orders
        <v-icon right> mdi-clipboard-text </v-icon>
      </v-btn>
    </template>

    <v-card tile>
      <v-toolbar max-height="100" flat dark color="secondary-4">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <span class="headline" style="text-transform: capitalize"
          >{{ $store.getters.title }} | Order Lines - {{ projectCode }}</span
        >
      </v-toolbar>

      <v-card-text>
        <br />

        <v-layout align-center justify-center column>
          <v-flex row align-center>
            <v-progress-circular
              style="text-align: center"
              v-if="loading"
              indeterminate
              color="grey darken-2"
            ></v-progress-circular>
          </v-flex>
        </v-layout>

        <p v-if="orderLines.length === 0 && loading === false">
          No order lines to display!
        </p>

        <p v-if="orderLines.length > 0 && loading === false">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-data-table :headers="headers" :items="orderLines" :search="search" :custom-sort="orderLineSort">
            <template v-slot:item.delivery_date="{ item }">
              <date-picker
                :completed-date="item.date_received"
                :due-date="item.delivery_date"
                :disabled="true"
              ></date-picker>
            </template>

            <template v-slot:item.order_date="{ item }">
              <date-picker
                :due-date="item.order_date"
                :disabled="true"
                :greyed-out="true"
              ></date-picker>
            </template>
          </v-data-table>
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import PlanningToolDatePicker from "../../base/date/PlanningToolDatePicker.vue";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "PlanningToolViewOrders",
  components: {
    "date-picker": PlanningToolDatePicker,
  },
  props: ["project-code"],
  data() {
    return {
      orderLines: [],
      dialog: false,
      search: "",
      headers: [
        {
          text: "Supplier",
          align: "start",
          filterable: true,
          value: "supplier_name",
        },
        {
          text: "QTY",
          align: "start",
          filterable: true,
          value: "qty_ordered",
        },
        {
          text: "QTY Received",
          align: "start",
          filterable: true,
          value: "qty_received",
        },
        {
          text: "Description",
          align: "start",
          filterable: true,
          value: "item_description",
        },
        {
          text: "Date Ordered",
          align: "start",
          filterable: true,
          value: "order_date",
        },
        {
          text: "Delivery Date",
          align: "start",
          filterable: true,
          value: "delivery_date",
        },
      ],
      loading: false,
    };
  },
  methods: {
    orderLineSort(items, index, isDesc) {
      if (index.length === 1) {
        index = index[0];
      }

      items.sort((a, b) => {
        if (index[0] == "order_date" || index[0] == "delivery_date") {
          if (!isDesc[0]) {
            return new Date(b[index]) - new Date(a[index]);
          } else {
            return new Date(a[index]) - new Date(b[index]);
          }
        } else {
          if (typeof a[index] !== "undefined" && typeof a[index] === "string") {
            if (!isDesc[0]) {
              return a[index]
                .toLowerCase()
                .localeCompare(b[index].toLowerCase());
            } else {
              return b[index]
                .toLowerCase()
                .localeCompare(a[index].toLowerCase());
            }
          } else if (typeof a[index] !== "undefined" && typeof a[index] === "number") {
            if (!isDesc[0]) {
              return a[index] - b[index];
            } else {
              return b[index] - a[index];
            }
          }
        }
      });

      return items;
    },
    getOrderLines() {
      this.loading = true;
      this.orderLines = [];
      let this_ = this;

      this.$http
        .get(
          this.$store.state.apiUrl +
            "/" +
            this.$store.state.departmentText +
            "/" +
            "get_order_lines" +
            "/" +
            this.projectCode,
          {
            headers: { Authorization: this.$store.getters.apiToken },
          }
        )
        .then((response) => {
          this.orderLines = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this_.$store.state.errors.push({
            id: uuidv4(),
            message:
              "Oops, could not retrieve the order lines for " +
              this.projectCode,
          });

          this.loading = false;

          if ('response' in e && e.response.status === 401) {
            this.$store.state.apiToken = null
            // Set local storage variable
            localStorage.setItem('token', null)
          }
        });
    },
  },
};
</script>