var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',{staticClass:"text-center",staticStyle:{"background-color":"white","width":"100%"}},[_c('br'),_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey darken-2"}})],1):_c('div',{staticStyle:{"background-color":"white"}},[_c('v-combobox',{staticClass:"ma-0",staticStyle:{"padding-left":"10px","padding-right":"10px"},attrs:{"label":"Add Process","dense":"","items":_vm.possibleProcesses},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addProjectProcess($event)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{attrs:{"loading":_vm.addProjectProcessLoading,"fab":"","x-small":"","text":""},on:{"click":_vm.addProjectProcess}},[_c('v-icon',[_vm._v("mdi-plus")])],1)]},proxy:true}]),model:{value:(_vm.newProcessText),callback:function ($$v) {_vm.newProcessText=$$v},expression:"newProcessText"}})],1),(_vm.processes.length === 0 && !_vm.loading)?_c('p',{staticClass:"pr-2 pl-2 pb-2 ma-0",staticStyle:{"background-color":"white"}},[_vm._v(" No processes found! ")]):_c('v-list',{staticClass:"pt-0",staticStyle:{"background-color":"white"}},_vm._l((_vm.processes),function(projectProcess){return _c('v-list-item',{key:projectProcess.id,staticClass:"pl-2",attrs:{"dense":""}},[[_c('v-list-item-action',{staticClass:"mr-3"},[(
              _vm.completeProjectProcessLoading &&
              projectProcess.id === _vm.completeProjectProcessIdClicked
            )?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey darken-2","size":"24"}}):_c('v-checkbox',{attrs:{"dense":"","ripple":false},on:{"click":function($event){return _vm.completeProjectProcess(
                projectProcess.id,
                projectProcess.completed
              )}},model:{value:(projectProcess.completed),callback:function ($$v) {_vm.$set(projectProcess, "completed", $$v)},expression:"projectProcess.completed"}})],1),_c('v-list-item-content',{staticClass:"pa-1"},[_c('v-list-item-title',{class:projectProcess.completed ? 'completed' : ''},[_vm._v(" "+_vm._s(projectProcess.display_name)+" ")]),_c('v-list-item-subtitle',[_c('date-picker',{attrs:{"due-date":projectProcess.delivery_date,"completed-date":projectProcess.completed_at_date
                  ? projectProcess.completed_at_date
                  : null,"id":projectProcess.id,"slug":"update_card_process_delivery_date","field":"delivery_date","project-code":_vm.projectCode}})],1)],1),_c('v-btn',{attrs:{"fab":"","text":"","x-small":"","loading":_vm.removeProjectProcessLoading &&
            _vm.removeProjectProcessIdClicked === projectProcess.id},on:{"click":function($event){return _vm.removeProjectProcess(projectProcess.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]],2)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }